body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #25262e;
}

#root {
  height: 100%;
  width: 100%;
}

video {
  border-radius: 10px;
  object-fit: cover;
}

.snackbar-success {
  background-color: #1dacd6 !important;
  border-radius: 10px !important;
}

.snackbar-error {
  background-color: #fe5e55 !important;
  border-radius: 10px !important;
}
